/* SRHInnovations.css */

@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slide-in {
    from {
      transform: translateY(-20px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .header {
    background-color: #00539F;
    color: #fff;
    padding: 20px;
    text-align: center;
    animation: fade-in 1s ease-in-out;
  }
  
  .header h1 {
    margin: 0;
    animation: slide-in 1s ease-in-out;
  }
  
  .container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #333;
    line-height: 1.6;
  }
  .service {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}

.service h2 {
    color: #333;
}

.service p {
    color: #555;
    line-height: 1.5;
}

@media screen and (min-width: 768px) {
    .service {
        width: 100%;
    }
}

.contact-info p {
  margin-bottom: 10px;
}

.contact-info strong {
  color: #333;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}
.contact-info a:hover {
  text-decoration: underline;
}

.contact-info {
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.animate-color {
  animation: changeColor 2s infinite;
}

@keyframes changeColor {
  0% { color: red; }
    10% { color: orange; }
    30% { color: green; }
    40% { color: blue; }
    50% { color: indigo; }
    60% { color: violet; }
    70% { color: blue; }
    80% { color: green; }
    100% { color: orange; }
}
  