.animated-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #343a40; /* Dark background */
    color: #ffffff; /* White text */
    padding: 10px 0; /* Padding for spacing */
    text-align: center; /* Center the text */
    border-top: 3px solid #ffc107; /* Eye-catching top border */
    animation: slideUp 2s ease-in-out; /* Animation */
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.btn-like {
    background-color: #ffc107; /* Yellow color */
    color: #343a40; /* Dark text */
    border: none;
    font-weight: bold;
    margin-top: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.btn-like:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(255, 193, 7, 0.6);
}  
.advantage-box {
    background-color: transparent;
/* Light background */
border: 1px solid #dee2e6;
/* Border */
border-radius: 5px;
/* Rounded corners */
padding: 20px;
/* Padding */
text-align: center;
/* Centered text */
opacity: 1;
/* Set opacity */
}

.transparent-border{
    background-color: transparent;
    
}
 .border-green {
    border: 2px solid green;
 }
 .border-dark-green {
    border: 2px solid rgb(1, 62, 1);
 }
 

.bg-polka {

background-image: radial-gradient(circle, #5a9bd3 1px, transparent 1px);
background-size: 20px 20px;
/* Adjust for bigger or smaller dots */
}

.profile-card {

border: 1px solid #dee2e6;
/* Border */
border-radius: 10px;
/* Rounded corners */
padding: 20px;
/* Padding */
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
/* Shadow effect */
display: flex;
/* Flex display for row layout */
align-items: center;
/* Vertically center items */
justify-content: center;
/* Center content */
border-color: rgb(25, 187, 66);
}

.profile-image {
width: 100px;
/* Image width */
height: 100px;
/* Image height */
border-radius: 50%;
/* Circular image */
margin-right: 20px;
/* Space between image and text */
}

.animated-text {
font-size: 5vh;
color: rgb(13, 1, 1);
/* Base color */
text-shadow: 0 0 5px #9be0c3,
    0 0 10px #54e2a7,
    0 0 15px #29b67b,
    0 0 20px #088a54;
animation: glow 0.5s infinite alternate;
}

.card-css {
background-color: #9be0c3;
border-radius: 0;
border-color: red;
}

@keyframes glow {
0% {
    text-shadow: 0 0 5px #9be0c3,
        0 0 10px #54e2a7,
        0 0 15px #29b67b,
        0 0 20px #088a54;
}

50% {
    text-shadow: 0 0 10px #9be0c3,
        0 0 20px #54e2a7,
        0 0 30px #29b67b,
        0 0 40px #088a54;
}

100% {
    text-shadow: 0 0 15px #9be0c3,
        0 0 30px #54e2a7,
        0 0 45px #29b67b,
        0 0 60px #088a54;
}
}

.bg-diagonal-split {
background: linear-gradient(120deg, #90cbb2 50%, #9be0c3 50%);
color: white;
}

.bg-diagonal-split-2 {
background: linear-gradient(120deg, #90cbb2 50%, #9be0c3 50%);
color: white;
}

.centered-block {
height: 10vh;
/* Full viewport height */
display: flex;
/* Flexbox to center content */
justify-content: center;
/* Center horizontally */
align-items: center;
/* Center vertically */
text-align: center;
/* Center text alignment */
/* Background color */
background-color: #90cbb2;
color: white;
/* Text color */
border-radius: 15px;
/* Rounded corners */
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
/* Subtle shadow for depth */
}

/* Container styles */
.scrolling-text-container {
background-color: #90cbb2;
border-radius: 4px;
overflow: hidden;
opacity: 0.6;
}

/* Inner container styles */
.scrolling-text-inner {
display: flex;
white-space: nowrap;
font-size: 2vh;
font-weight: 600;
padding: 8px 0;
color: black;
}

/* Text styles */
.scrolling-text {
display: flex;
}

.scrolling-text-item {
padding: 0 30px;
}

/* Apply the animation to the text items */
.scrolling-text-inner>div {
animation: var(--direction) var(--marquee-speed) linear infinite;
}

/* Pause the animation when a user hovers over it */
.scrolling-text-container:hover .scrolling-text-inner>div {
animation-play-state: paused;
}

/* Setting the Animation using Keyframes */
@keyframes scroll-left {
0% {
    transform: translateX(0%);
}

100% {
    transform: translateX(-100%);
}
}

@keyframes scroll-right {
0% {
    transform: translateX(-100%);
}

100% {
    transform: translateX(0%);
}
}

.color-bg {
background-color: #9be0c3;

}